import React, { ChangeEvent, FC, useEffect, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import InputMask from "react-input-mask"
import { Box, InputAdornment, MenuItem, OutlinedTextFieldProps, TextField } from "@material-ui/core"

import { useAppSelector } from "../../../../../hooks/storeHooks"
import { selectClinicSettings } from "../../../../../store/clinic/clinicSettings.selectors"
import CountrySelect from "../../../../commonFormItems/countrySelect/CountrySelect.component"
import SexCheckbox from "../../../../commonFormItems/customCheckboxes/SexCheckbox.component"
import DisabledFormFieldTooltip from "../../../../commonFormItems/disabledFormFieldTooltip/DisabledFormFieldTooltip.component"
import TextFieldController from "../../../../commonFormItems/textFieldController/TextFieldController.component"
import TimeZoneSelect from "../../../../commonFormItems/timeZoneSelect/TimeZoneSelect.component"
import {
  disabledFormFieldTooltipInputProps, resolveDisableInputProps
} from "../../../../informationPanel/fillUserDataInformationPanel/FillUserDataInformationPanel.component"
import { revertDateFormat } from "../../../../../utils/dayJs/ManipulateDate"
import { getDocumentTypesForCountry, setProperDefaultDocumentType } from "../UserPersonalData.utils"
import { MainCountriesTypes, UserDataType, UserDocumentData, UserDocumentType } from "../../../../../store/user/user.types"
import { useUserPersonalDataEditModalStyles } from "../UserPersonalDataEditModal/UserPersonalDataEditModal.styles"

interface UserPersonalDataEditFormProps {
  userData: UserDataType;
  userDocumentData: UserDocumentData;
  loading: boolean;
}

const UserPersonalDataEditForm: FC<UserPersonalDataEditFormProps> = ({
  userData,
  userDocumentData,
  loading,
}) => {
  const {t} = useTranslation()
  const form = useFormContext()
  const { hide } = useUserPersonalDataEditModalStyles()
  const [patientHavePesel, setPatientHavePesel] = useState(false)
  const [documentTypes, setDocumentTypes] = useState<UserDocumentData[]>(getDocumentTypesForCountry(userData?.nationality))
  const [showDocumentTypeInput, setShowDocumentTypeInput] = useState(userData.nationality !== MainCountriesTypes.PL)
  const { frontendSettings: { modules } } = useAppSelector(selectClinicSettings)
  const isDifferentDateFormatEnabled = modules.includes("enable_different_date_format")
  const canPatientEditDataUntilFirstConsultationFinished = modules.includes("enable_data_update") && !userData?.isSensitiveDataEditingDisabled

  const handleChangeNationality = (onChange: (value: string) => void) => (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value

    form.setValue("documentType", "")
    setDocumentTypes(getDocumentTypesForCountry(newValue))
    setShowDocumentTypeInput(newValue !== MainCountriesTypes.PL)
    onChange(newValue)
  }

  const handleChangeDocumentType = (onChange: (value: string) => void) => (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value

    setPatientHavePesel(newValue === UserDocumentType.PESEL)
    onChange(newValue)
  }

  useEffect(() => {
    const documentTypeValue = setProperDefaultDocumentType(userData?.nationality, userData?.pesel ? UserDocumentType.PESEL : userData?.identity_document_type) || documentTypes[0]?.value
    const showPeselField = documentTypeValue === UserDocumentType.PESEL && (userData?.pesel || !userData?.identity_number)
    
    form.setValue("documentType", documentTypeValue)
    setPatientHavePesel(!!showPeselField)
  }, [documentTypes])

  return (
    <Box>
      <TextFieldController
        name="firstName"
        label={t("user:name")}
        disabled={loading}
        isRequired
        InputProps={
          resolveDisableInputProps(
            () => disabledFormFieldTooltipInputProps(userData?.first_name, loading),
            canPatientEditDataUntilFirstConsultationFinished
          )
        }
      />

      <TextFieldController
        name="lastName"
        label={t("user:surname")}
        disabled={loading}
        isRequired
        InputProps={
          resolveDisableInputProps(
            () => disabledFormFieldTooltipInputProps(userData?.last_name, loading),
            canPatientEditDataUntilFirstConsultationFinished
          )
        }
      />

      <Controller
        name="nationality"
        control={form.control}
        render={({
          field: {onChange, value},
        }) => (
          <CountrySelect
            id="nationality"
            label={t("user:country")}
            placeholder={t("user:country")}
            fullWidth
            required
            disabled={loading}
            value={value}
            onChange={handleChangeNationality(onChange)}
            InputProps={ canPatientEditDataUntilFirstConsultationFinished ? undefined : {
              disabled: loading || !!userData?.pesel || !!userData?.identity_number,
              endAdornment: !!userData?.pesel || !!userData?.identity_number
                ? (
                  <InputAdornment position="end">
                    <DisabledFormFieldTooltip/>
                  </InputAdornment>
                ) : undefined,
            }}
          />
        )}
      />

      {showDocumentTypeInput && (
        <Controller
          name="documentType"
          control={form.control}
          render={({
            field: {onChange, value},
            fieldState: {error, invalid},
          }) => (
            <TextField
              value={value}
              select
              label={t("user:documentType")}
              placeholder={t("user:documentType")}
              fullWidth
              onChange={handleChangeDocumentType(onChange)}
              error={invalid}
              helperText={error?.message}
              InputProps={ canPatientEditDataUntilFirstConsultationFinished ? undefined : {
                disabled: loading || !!userData?.identity_document_type,
                endAdornment: !!userData?.identity_document_type
                  ? (
                    <InputAdornment position="end">
                      <DisabledFormFieldTooltip/>
                    </InputAdornment>
                  ) : undefined,
              }}
            >
              {documentTypes.map((option) => (
                <MenuItem
                  // ES Documents exceptions: hide option which was set just to show label
                  className={option.value === UserDocumentType.ES_IDENTITY_CARDS_TOGETHER ? hide : undefined}
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      )}

      {patientHavePesel ? (
        <TextFieldController
          name="pesel"
          label={t("user:pesel")}
          disabled={loading}
          isRequired
          InputProps={
            resolveDisableInputProps(
              () => disabledFormFieldTooltipInputProps(userDocumentData.value, loading),
              canPatientEditDataUntilFirstConsultationFinished
            )
          }
        />
      ) : (
        <>
          <TextFieldController
            name="identityNumber"
            label={t("user:identityCardNumberLabel")}
            disabled={loading}
            isRequired
            InputProps={
              resolveDisableInputProps(
                () => disabledFormFieldTooltipInputProps(userDocumentData.value, loading),
                canPatientEditDataUntilFirstConsultationFinished
              )
            }
          />
          <Controller
            name="birthDate"
            control={form.control}
            render={({
              field: {onChange, value},
              fieldState: {error, invalid},
            }) => (
              <InputMask
                id="birthDate"
                mask={isDifferentDateFormatEnabled ? "99-99-9999" : "9999-99-99"}
                value={isDifferentDateFormatEnabled ? revertDateFormat(value, false) : value}
                onChange={onChange}
                disabled={loading}
              >
                {(props: OutlinedTextFieldProps) => (
                  <TextField
                    helperText={error?.message}
                    error={invalid}
                    disabled={loading}
                    fullWidth
                    label={t("user:" + (isDifferentDateFormatEnabled ? "differentFormatBirthDate" : "birthDate"))}
                    InputProps={
                      resolveDisableInputProps(
                        () => disabledFormFieldTooltipInputProps(userDocumentData.value, loading),
                        canPatientEditDataUntilFirstConsultationFinished
                      )
                    }
                    {...props}
                  />
                )}
              </InputMask>
            )}
          />
          <SexCheckbox
            name="gender"
            disabled={loading || userData?.gender !== null}
            initialValue={userData?.gender}
          />
        </>
      )}

      <Controller
        name="defaultTimezone"
        control={form.control}
        render={({
          field: {onChange, value},
          fieldState: {error, invalid},
        }) => (
          <TimeZoneSelect
            id="default-timezone"
            label={t("user:defaultTimezone")}
            placeholder={t("user:defaultTimezone")}
            fullWidth
            disabled={loading}
            value={value || null}
            onChange={onChange}
            error={invalid}
            helperText={error?.message}
            InputProps={
              resolveDisableInputProps(
                () => disabledFormFieldTooltipInputProps(userData?.default_timezone, loading),
                canPatientEditDataUntilFirstConsultationFinished
              )
            }
          />
        )}
      />
    </Box>
  )
}

export default UserPersonalDataEditForm
