import React, {FC, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {useSelector} from "react-redux"
import {useLocation} from "react-router"
import DateFnsUtils from "@date-io/date-fns"
import { CssBaseline, MuiThemeProvider } from "@material-ui/core"
import {MuiPickersUtilsProvider} from "@material-ui/pickers"
import {push} from "connected-react-router"

import ApiErrorPopup from "./components/apiErrorPopup/ApiErrorPopup.component"
import LoaderBox from "./components/common/loaderBox/LoaderBox.component"
import LoginPagePopup from "./components/LoginPagePopup/LoginPagePopup.component"
import SessionCode from "./components/sessionManager/sessionCode/SessionCode.component"
import {
  checkFeatureAvailability,
  getClinicThemeFromStyles,
  handleCheckCustomIntegrationPermission} from "./store/clinic/clinicSettings.utils"
import generateUuid from "./utils/generateUuid"
import {RoutePath} from "./routes/Routes.types"
import {Theme} from "@material-ui/core/styles"

import api from "./api/api"
import useMakeQuery from "./api/graphql/hooks/useMakeQuery"
import {userEventsConfig} from "./api/routes"
import useGetSubdomainName from "./hooks/useGetSubdomainName"
import useOpenReplayTracker, {tracker} from "./hooks/useOpenReplayTracker"
import useUserCookies from "./hooks/useUserCookies"
import {dateFnsTranslations} from "./i18n/dateFnsTranslations"
import Routes from "./routes/Routes"
import {selectClinicSettings} from "./store/clinic/clinicSettings.selectors"
import {setClinicSettings} from "./store/clinic/clinicSettings.slice"
import {selectUserId} from "./store/session/session.selectors"
import {initSessionByFusionAuth} from "./store/session/session.slice"
import theme from "./themes/default/theme"
import store from "./store"
const CUSTOM_INTEGRATION_CHECK_PERMISSION_ENABLED = "1"

const App: FC = () => {
  const [appTheme, setAppTheme] = useState<Theme>(theme)
  const [appThemeIsSet, setAppThemeIsSet] = useState<boolean>(false)
  const clinicSettings = useSelector(selectClinicSettings)
  const {i18n} = useTranslation()
  const {getClinicSettingsQuery} = useMakeQuery()
  const {subdomain} = useGetSubdomainName()
  const clinicSettingsData = getClinicSettingsQuery(subdomain)
  const params = new URLSearchParams(window.location.search)
  const paramsObject = Object.fromEntries(params)
  const [customIntegrationRedirect, setCustomIntegrationRedirect] = useState(paramsObject.sso === CUSTOM_INTEGRATION_CHECK_PERMISSION_ENABLED)
  const [caseNumber, setCaseNumber] = useState<string | undefined>(paramsObject.case_number)
  const [caseId, setCaseId] = useState<string | undefined>(paramsObject.case_id)
  const userId = useSelector(selectUserId)
  const {trackerStart} = useOpenReplayTracker()
  const checkCustomIntegrationPermission = checkFeatureAvailability().enableBenefitSystemIntegration && customIntegrationRedirect
  const isLoginPage = customIntegrationRedirect ? useLocation().pathname.includes("login") : false
  const uniqueSessionId = sessionStorage.getItem("sessionId")
  const universalAssistanceSso = customIntegrationRedirect && caseNumber

  useUserCookies()

  useEffect(() => {
    trackerStart()
    if(!uniqueSessionId) {
      sessionStorage.setItem("sessionId", generateUuid())
    }
  }, [])

  useEffect(() => {
    const clinicData = clinicSettingsData?.data?.edges?.[0]?.node

    if (!clinicSettingsData.dataLoading) {
      if (clinicData?.id) {
        store.dispatch(setClinicSettings(clinicData))
      } else {
        setAppThemeIsSet(true)
        store.dispatch(push(`/${i18n.language}${RoutePath.CLINIC_NOT_FOUND}`))
      }
    }
  }, [clinicSettingsData])

  useEffect(() => {
    const clinicStyles = clinicSettings?.frontendSettings?.style
    const clinicId = clinicSettings?.frontendSettings?.clinicId
    if (clinicId && clinicStyles && !appThemeIsSet) {
      if (clinicStyles.mainColor && clinicStyles.hoverColor && clinicStyles.lightBgColor && clinicStyles.gradientColor && clinicStyles.chatBgColor) { // cannot set color as empty string
        getClinicThemeFromStyles(clinicStyles, clinicId).then( ( newTheme)=>
          setAppTheme(newTheme)
        )
      }
    }

    if (clinicSettings?.domain) {
      if (!appThemeIsSet) {
        tracker.setMetadata("clinicName", clinicSettings?.name)
        tracker.setMetadata("clinicId", clinicSettings?.frontendSettings?.clinicId)
      }

      if (universalAssistanceSso) {
        if (userId) {
          api.request({
            ...userEventsConfig,
            data: {
              name: "universal_assistance_sso_success",
              params: {
                caseNumber,
                caseId
              }
            }
          })

          setCaseNumber(undefined)
          setCaseId(undefined)
          setAppThemeIsSet(true)
          setCustomIntegrationRedirect(false)
        } else {
          store.dispatch(initSessionByFusionAuth())
        }
      }

      if (checkCustomIntegrationPermission) {
        if (userId) {
          handleCheckCustomIntegrationPermission(setAppThemeIsSet, setCustomIntegrationRedirect)
        } else {
          store.dispatch(initSessionByFusionAuth())
        }
      } else {
        setAppThemeIsSet(true)
      }
    }
  }, [clinicSettings, userId])

  if (!appThemeIsSet && !isLoginPage) {
    return <LoaderBox boxType="fullPage" boxClassName="b2b-loader" />
  }

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={dateFnsTranslations[i18n.language as keyof typeof dateFnsTranslations]}
    >
      <MuiThemeProvider theme={appTheme}>
        <CssBaseline />
        <LoginPagePopup/>
        <ApiErrorPopup/>
        <Routes/>
        <SessionCode />
      </MuiThemeProvider>
    </MuiPickersUtilsProvider>
  )
}

export default App
